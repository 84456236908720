import React, { useState } from 'react';

import {MIAWChat, MIAWChat2 } from '../MIAWChat/MIAWChat';
import ChatSFHC from '../ChatSFHC/ChatSFHC';

// ChatSwitch used to handle chat components
// when an agent is online, show MIAWChat
// otherwise show ChatSFHC

const ChatSwitch = ({ action }) => {

  const [showMIAW, setShowMIAW] = useState(true);
  
  const callbackMiawCheck = () => {
    console.log("set to false");
    setShowMIAW(false);
    action(false);
  };

  return showMIAW ? (
    <MIAWChat action={action} callbackMiawCheck={callbackMiawCheck} />
  ) : (
    <></>
  );
};

export default ChatSwitch;
